import React, { useContext, useEffect, useState } from "react";
import Filter1Icon from "@mui/icons-material/Filter1";
import Filter2Icon from "@mui/icons-material/Filter2";
import LockIcon from "@mui/icons-material/Lock";
import "./bookinguser.css";
import { LoginContext } from "../../Context/UserLoginContext";
import { updateUserInfo } from "../../api/getLogin.api";
import PayHotelPartial from "../PayHotelPartial/PayHotelPartial";

const BookingUserDetails = () => {
  // const []
  const { userLoginData, setUserLoginData } = useContext(LoginContext);
  const [toggleComponent, setToggleComponent] = useState(false);

  useEffect(() => {
    if (userLoginData?.first_name && userLoginData?.last_name) {
      const name = `${userLoginData?.first_name} ${userLoginData?.last_name}`;
      setFullname(name);
    }
    if (userLoginData?.email) {
      setUserEmail(userLoginData?.email);
    }
    if (userLoginData?.phone_number) {
      setMobile(userLoginData?.phone_number);
    }
  }, [toggleComponent, userLoginData]);
  const [fullname, setFullname] = useState("");
  const [userEmail, setUserEmail] = useState("");

  const [mobile, setMobile] = useState("");
  const handleModify = (e) => {
    setToggleComponent(false);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    // customer_id: 667;
    // email: "asad.ayyubi25@gmail.com";
    // first_name: "Asad";
    // last_name: "Ayyubi";
    // phone_number: "9653214526";
    // username;

    const userData = {
      ...userLoginData,
      first_name: fullname.split(" ")[0],
      last_name: fullname.split(" ")[1],
      username: fullname,
      email: userEmail,
      customer_id: userLoginData?.id,
    };
    console.log(userData, "from continue");
    updateUserInfo(userData);
    setUserLoginData(userData);
    setToggleComponent(true);
    console.log();
  };
  if (!toggleComponent) {
    return (
      <div>
        <div className="booking-part1-cont">
          <div className="booking-user-header">
            <Filter1Icon />
            <p style={{color:"rgb(37, 128, 57)",fontWeight:"700",marginLeft:"5px", fontSize:"20px"}} className="booking-info-p-tag">Enter your details</p>
          </div>
          <p
            className="booking-info-p-tag"
            style={{ fontSize: "14px", padding: "14px", textAlign: "justify" }}
          >
            We will use these details to share your booking information
          </p>
          <div className="form-user-details">
            <form>
              <div className="user-form-fields">
                <div>
                  <p className="booking-user-p">Full Name</p>
                  <input
                    className="input-booking"
                    type="text"
                    required
                    value={fullname}
                    onChange={(e) => setFullname(e.target.value)}
                  />
                </div>
                <div>
                  <p className="booking-user-p">Email</p>
                  <input
                    type="email"
                    required
                    className="input-booking"
                    value={userEmail}
                    onChange={(e) => setUserEmail(e.target.value)}
                  />
                </div>

                <div className="user-form-mobile-number">
                  <p className="booking-user-p">Mobile Number</p>
                  <input
                    className="input-booking"
                    type="number"
                    value={mobile}
                    required
                    disabled={true}
                    // onChange={(e) => setMobile(e.target.value)}
                  />
                </div>
              </div>
              <div
                className={`booking-button ${
                  fullname.length > 3 ? "" : "booking-button1"
                }`}
              >
                <button
                  disabled={
                    fullname.length > 3 && userEmail.length > 6 ? false : true
                  }
                  onClick={handleSubmit}
                >
                  Continue
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <>
        <div className="user-booking-page2">
          <div className="booking-user-modify-cont">
            <div className="user-details-modifiy-cont">
              <div className="booking-user-header">
                <Filter1Icon />
                <p style={{ marginLeft: "10px" ,color:"rgb(37, 128, 57)",fontWeight:"700"}}>Your details</p>
              </div>
              <div className="modify-button">
                <button onClick={handleModify}>Modify</button>
              </div>
            </div>
            <div style={{textAlign:"justify", width:"80%" , margin:"auto"}}>
              Name : <span style={{fontWeight:"600"}}> {fullname}</span> <br />
              Email : <span style={{fontWeight:"600"}}>{userEmail}</span> <br />
              Mob : <span style={{fontWeight:"600"}}> {mobile}</span>
            </div>
          </div>
        </div>
        <div className="booking-user-header-wrapper">
          <div className="booking-user-header1">
            <div className="booking-user-header">
              <Filter2Icon />
              <p style={{ marginLeft: "10px" ,color:"rgb(37, 128, 57)",fontWeight:"700"}}>Complete Your Booking</p>
            </div>
            <div
              style={{
                fontSize: "10px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginRight: "40px",
              }}
            >
              <LockIcon style={{ fontSize: "12px", color: "green" }} />
              <span>100% secure payments</span>
            </div>
          </div>
        </div>
        <div>
          <PayHotelPartial />
        </div>
      </>
    );
  }
};

export default BookingUserDetails;
