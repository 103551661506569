import axios from "axios";

const BASE_URL = "https://api.ratebotai.com:8443";
const API_GET_AMENITIES = BASE_URL + "/get_amenities_data";

export const getHotelAmenities = async (
  params = {
    ids: [5, 34, 36, 35],
  },
  setFunction
) => {
  const res = await axios.post(API_GET_AMENITIES, params);
  setFunction(res.data.data);
};
