import axios from "axios";

const BASE_URL = "https://api.ratebotai.com:8443";
const API_GET_HOTEL_FOOTER_DATA = BASE_URL + "/patch_get_hotel_link_data";
const API_GET_HOTEL_LOGO_DATA = BASE_URL + "/get_hotel_logo_data";

export const getHotelFooter = async (params={},func) => {
    const res = await axios.post(API_GET_HOTEL_FOOTER_DATA,params);
    console.log(res);
    func(res.data.data)
}

export const getLogoFooter = async (params={},func) => {
    const res = await axios.post(API_GET_HOTEL_LOGO_DATA,params);
    console.log(res,"comming from footer logo");
    func(res.data.data)
}