import axios from "axios";

const BASE_URL = "https://api.ratebotai.com:8443";
// https://api.ratebotai.com:8443/login_by_otp

const API_GET_LOGIN_OTP = BASE_URL + "/login_by_otp";
const API_GET_CHECK_OTP = BASE_URL + "/check_otp";
const API_UPDATE_USER_INFO = BASE_URL + "/update_customer_info_data";

export const loginByOtp = async (params = {}, func) => {
  const res = await axios.post(API_GET_LOGIN_OTP, { phone_number: params });
  console.log(res.data.otp, "res from otp");
  func(res.data.otp);
};

export const verifyOtp = async (params = {}, func) => {
  const res = await axios.post(API_GET_CHECK_OTP, params);
  console.log(res.data.data);
  localStorage.setItem("userData", JSON.stringify(res.data.data));
  func(res.data.data);
};
export const updateUserInfo = async (params = {}, func) => {
  const res = await axios.post(API_UPDATE_USER_INFO, params);
  console.log(res, "from update user");
};

