import { createContext, useEffect, useState } from "react";

export const LoginContext = createContext({});

export const LoginProvider = ({ children }) => {
  const [userOtp, setUserOtp] = useState({});
  const storedStatus = localStorage.getItem("status");
  const storedUserData = localStorage.getItem("userData");

  let initialIsLoggedIn = false;
  let initialLoggedInData = "SignUp/SignIn";

  try {
    // Attempt to parse the stored status, set to false if parsing fails
    initialIsLoggedIn = storedStatus ? JSON.parse(storedStatus) : false;
    initialLoggedInData = storedStatus
      ? JSON.parse(storedUserData)
      : "SignUp/SignIn";
  } catch (error) {
    console.error("Error parsing stored status:", error);
    // Set to false in case of an error during parsing
    initialIsLoggedIn = false;
    initialLoggedInData = "SignUp/SignIn";
  }

  const [isLoggedIn, setIsLoggedIn] = useState(initialIsLoggedIn);
  const [userLoginData, setUserLoginData] = useState(initialLoggedInData);
  const value = {
    userLoginData,
    setUserLoginData,
    userOtp,
    setUserOtp,
    isLoggedIn,
    setIsLoggedIn,
  };

  return (
    <LoginContext.Provider value={value}>{children}</LoginContext.Provider>
  );
};
