import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import { createSelector } from "reselect";
import "../Layout/layout.css";
import Calendar from "../CheckInCheckOutCalendar/Calendar";
import GuestCount from "../GuestCount/GuestCount";
import GrossTotalCard from "../GrossTotalCard/GrossTotalCard";
import FilterRooms from "../FilterRooms/FilterRooms";
import RoomsList from "../RoomsList/RoomsList";
import "./homepage.css";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: "60%",

  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const selectHotelData = createSelector(
  (store) => store?.reducerHotel?.isLoading,
  (store) => store?.reducerHotel?.hotel,

  (isLoading, hotel) => ({
    isLoading,
    hotel,
  })
);
const HomePage = () => {
  const { isLoading, hotel } = useSelector(selectHotelData);
  const store = useSelector((store) => store?.urlReducer?.parmas_for_api);
  const [open, setOpen] = React.useState(false);
  const [selectedAge, setSelectedAge] = useState([
    store?.room_info?.reduce((acc, curr) => {
      return acc + curr?.child_0_to_6;
    }, 0),
    store?.room_info?.reduce((acc, curr) => {
      return acc + curr?.child_7_to_12;
    }, 0),
  ]);
  const [appliedCoupons, setAppliedCoupons] = useState(false);
  const [selectedCoupon, setSelectedCoupon] = useState([]);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // console.log(hotel, "comming from homepage");
  useEffect(() => {
    console.log(store, "comming from url homepage reducer");
    if (store?.no_of_children > 0 || selectedAge[0] < 0 || selectedAge[1] < 0) {
    
      handleClose();
    }
  
  }, []);


  // useEffect(() => {
  //   if (store?.no_of_children > 0) {
  //     alert( "child age");
  //   }else if(selectedAge[0] < 0 || selectedAge[1] < 0){
  //     return null
  //   }
   
   
  // }, []);

  const handleAppliedCoupons = (res, selectedC) => {
    setAppliedCoupons(res);
    setSelectedCoupon(selectedC);
  };
  return (
    <div className="page-content">
      <div className="container-left-right">
        <div className="container-left">
          {/* <div className="container-calendar-guest">
            <Calendar />
            <GuestCount />
          </div> */}
          <div className="select-room-text">
            <p>Select a Room</p>
          </div>
          <div className="filter-container">
            <FilterRooms />
          </div>
          {hotel?.occupancy === "low_occupancy" ? (
            <div className="rooms-list-container">
              please go back no rooms available for this combination.
            </div>
          ) : (
            <div className="rooms-list-container">
              <RoomsList hotel={hotel || []} />
            </div>
          )}
        </div>
        <div className="container-right">
          <GrossTotalCard
            appliedCoupons={appliedCoupons}
            selectedCoupon={selectedCoupon}
            handleAppliedCoupons={handleAppliedCoupons}
          />
        </div>

        {
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <Typography id="modal-modal-title" variant="h6" component="h2">
                Please add the ages of your children
              </Typography>
              <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                To view the accurate price and to book a room your children's
                ages are required
              </Typography>
              <Button
                variant="contained"
                onClick={handleClose}
                size="small"
                sx={{ mt: 2, bgcolor: "#01509d", textTransform: "none" }}
              >
                Add Ages
              </Button>
            </Box>
          </Modal>
        }
      </div>
    </div>
  );
};

export default HomePage;
