import React, { useContext } from "react";
import { HotelContext } from "../../Context/HotelContext";
import "./payhotelpartial.css";

const PayPartial = ({ payHotel, partial_tax_gst, partialamountpercentage }) => {
  const { setPayPartialGross } = useContext(HotelContext);
  return (
    <div className="pay-partial-container">
      <p>
        <strong>Partial Payment:</strong> Confirm your stay by paying a portion
        today. Pay the remaining balance at the hotel. <br />
        <strong> Full Payment:</strong> Secure your reservation by making the
        full payment today.
      </p>
      {partialamountpercentage !== 100 && (
        <div className="pay-hotel-btn">
          <button disabled={payHotel} onClick={() => setPayPartialGross(true)}>
            Pay Partial
          </button>
       
        </div>
      )}
      <div className="pay-hotel-btn">
        <button onClick={() => setPayPartialGross(false)}>Pay Full</button>
      </div>
    </div>
  );
};

export default PayPartial;
