import React, { useContext } from "react";
//import ButtonPrimary from "../../components/button/ButtonPrimary";
//import BookingDetails from "../../components/BookingDetails";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect } from "react";
import "./voucher.css";
import axios from "axios";
import { useState } from "react";
import { HotelContext } from "../../Context/HotelContext";
import BookingDetails from "../../Components/BookingDetails/BookingDetails";
//import LoaderPrimary from "../../layout/Loader/LoaderPrimary";

const Voucher = () => {
  const { bookingInfo } = useContext(HotelContext);
  console.log(bookingInfo);

  // console.log("..booking info...", location?.state?.data);

  // const printPdf = () => {
  //     const queryParams = new URLSearchParams(window.location.search);
  //     const custumer_id = queryParams.get("custumer_id");
  //     const booking_id = queryParams.get("booking_id");
  //     navigate(`https://api.ratebotai.com:8443/booking_pdf?booking_id=${location?.state?.data?.booking_id || booking_id}&customer_id=${location?.state?.data?.customer_id || custumer_id}`)
  //     // return axios.get(`https://api.ratebotai.com:8443/booking_pdf?booking_id=${location?.state?.data?.booking_id || booking_id}&customer_id=${location?.state?.data?.customer_id || custumer_id}`);
  //     return axios.post('https://api.ratebotai.com:8443/get_customer_booking_info', {customer_id: userProfileDetails.id}).then((response) => {
  //         setCustomerBookingInfo(response.data.data);
  // })

  const Header = function (props) {
    const { bookInfo } = props;
    // console.log(props);
    if (bookInfo?.booking_status === "confirmed") {
      return (
        <div className="voucher-stay-div">
          <div className="confirm text">
            <h2>Great! Your stay is confirmed.</h2>
          </div>
          <div className="confirm-sub-text">
           <p> You will soon receive an email confirmation on {bookInfo?.email}</p>
          </div>
          <div className="print-button">
            <a
              href={`https://api.ratebotai.com:8443/booking_pdf?booking_id=${bookInfo?.booking_id}&customer_id=${bookInfo?.customer_id}`}
              target="_blank"
            >
              <button className="check-login-btn" style={{width:"110px"}}>Print</button>
            </a>

            {/* <ButtonPrimary
              text="Print"
              link={`https://api.ratebotai.com:8443/booking_pdf?booking_id=${bookingInfo.booking_id}&customer_id=${bookingInfo.customer_id}`}
            /> */}
            {/* <ButtonPrimary text="Print" link={`https://api.ratebotai.com:8443/booking_pdf?booking_id=${location?.state?.data?.booking_id || booking_id}&customer_id=${location?.state?.data?.customer_id || custumer_id}`}/> */}
          </div>
        </div>
      );
    } else if (bookInfo?.booking_status === "cancelled") {
      return (
        <div className="cancelled text">Your booking has been cancelled</div>
      );
    } else if (bookInfo?.booking_status === "pending") {
      return (
        <>
          <div className="cancelled text">Your booking is in pending</div>
          <div className="print-button">
            {/* <ButtonPrimary text="Confirm Now" /> */}
          </div>
        </>
      );
    }
  };
  if (bookingInfo) {
    return (
      <div className="confirmation-page column-flex">
        <div className="header section">
          <Header bookInfo={bookingInfo} />
        </div>
        <div className="main-section-shadow">
          <BookingDetails bookInfo={bookingInfo} />
        </div>
      </div>
    );
  } else {
    return <div>Loading.......</div>;
  }
};

export default Voucher;
