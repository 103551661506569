import React, { useContext, useState } from 'react'
import RoomCard from './RoomCard';
import { useSelector } from 'react-redux';
import "./roomcard.css"
import { HotelContext } from '../../Context/HotelContext';

const RoomsList = () => {
    const store = useSelector((store) => store?.reducerHotel?.hotel?.rooms_info);
    const singleHotelData = useSelector((store) => store?.reducerHotel?.hotel?.hotel_data);
    const [roomsInfo,setRoomsInfo] = useState(store?.map((item) => item));
        console.log(store);
        const {
          selectedRoom,
          setSelectedRoom,
          setSelectedRoomInfo,
          selectedRoomInfo,
        } = useContext(HotelContext);
    // const {rooms_info} = store;
    // if(id === 1) {
    //   // setSelectedButton(buttonId);
    //   // setSelectedButton((prevSelectedButton) =>
    //   //   prevSelectedButton === buttonId ? null : buttonId
    //   // );
    //   setSelectedRoom(rooms[0]);
    //   setSelectedRoomInfo(item);
    // }


  return (
    <div className='room-list-container'>
        {roomsInfo?.map((item,i) => <RoomCard key={i+1} item={item} id={i+1} singleHotelData={singleHotelData}/>)}
    </div>
  )
}
 
export default RoomsList