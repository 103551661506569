import React, { useContext, useEffect, useState } from "react";
import PeopleIcon from "@mui/icons-material/People";
import { useDispatch, useSelector } from "react-redux";
import "./guestcount.css";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";
import {
  updateAdults,
  updateChildren,
  updateRoomInfo,
} from "../../Redux/Urldata/action";
import { constructRoomsInfo } from "../../utilFunction/constructRoomsInfo";
import { getHotel } from "../../Redux/Hotel/action";
import { getHotelById } from "../../api/getHotel.api";
import { HotelContext } from "../../Context/HotelContext";

const GuestCount = () => {
  const { parmas_for_api } = useSelector((store) => store?.urlReducer);
  const {
    no_of_adults,
    room_count,
    no_of_children,
    room_info,
    from_date,
    to_date,
    id,
  } = useSelector((store) => store?.urlReducer?.parmas_for_api);
  const [childCount, setChildCount] = useState(parmas_for_api?.no_of_children);
  const [toggleDisplay, setToggleDisplay] = useState(false);
  const [selectedValues, setSelectedValues] = useState([
    room_info?.reduce((acc, curr) => {
      return acc + curr?.child_0_to_6;
    }, 0),
    room_info?.reduce((acc, curr) => {
      return acc + curr?.child_7_to_12;
    }, 0),
  ]);

  const [dataAgeText, setDataAgeText] = useState([
    { title1: "Younger Children", title2: "Age:- 0 - 6 years" },
    { title1: "Older Children", title2: "Age:- 7 - 12 years" },
  ]);
  // const [hotelData, setHotelData] = useState([]);
  const { hotelData, setHotelData, setParamsHotel, paramsHotel } =
    useContext(HotelContext);
  const dispatch = useDispatch();

  const handleClose = () => {
    setToggleDisplay(false);
  };

  const handleIncrementAdults = () => {
    if (
      parmas_for_api?.no_of_adults >= 1 &&
      parmas_for_api?.no_of_adults < room_count * 3 &&
      parmas_for_api?.no_of_adults <
        room_count * 3 - parmas_for_api?.no_of_children
    ) {
      dispatch(updateAdults(parmas_for_api?.no_of_adults + 1));
    } else {
      if (
        room_count * 3 === parmas_for_api?.no_of_adults ||
        room_count * 3 ===
          parmas_for_api?.no_of_adults + parmas_for_api?.no_of_children
      ) {
        alert(
          `Maximum occupancy of ${room_count} room is ${room_count * 3} people.`
        );
      }
      // else {

      // }
    }
  };

  const handleDecrementAdults = () => {
    if (parmas_for_api.no_of_adults > 1) {
      dispatch(updateAdults(parmas_for_api.no_of_adults - 1));
    } else {
      alert(" Minimum of 1 Adult is required for room booking");
    }
  };

  const handleIncrementChildren = () => {
    if (
      parmas_for_api?.no_of_children <
      room_count * 3 - parmas_for_api?.no_of_adults
    ) {
      dispatch(updateChildren(parmas_for_api.no_of_children + 1));
    } else {
      if (
        room_count * 3 === parmas_for_api?.no_of_adults ||
        room_count * 3 ===
          parmas_for_api?.no_of_adults + parmas_for_api?.no_of_children
      ) {
        alert(
          `Maximum occupancy of ${room_count} room is ${room_count * 3} people.`
        );
      }
      // else {

      // }
    }
  };

  const handleDecrementChildren = () => {
    if (parmas_for_api?.no_of_children > 0) {
      dispatch(updateChildren(parmas_for_api.no_of_children - 1));
    }
  };

  const handleAgeSelect = (index, value) => {
    const updatedValues = [...selectedValues];
    updatedValues[index] = parseInt(value, 10); // Parse value to an integer
    setSelectedValues(updatedValues);
  };

  const calculateRoomInfo = () => {
    const roomConfigurations = [];

    let adultsPerRoom = Math.floor(parmas_for_api.no_of_adults / room_count);
    let child0to6PerRoom = Math.floor(selectedValues[0] / room_count);
    let child7to12PerRoom = Math.floor(selectedValues[1] / room_count);
    let remainingAdults = parmas_for_api.no_of_adults % room_count;
    let remainingChild0to6 = selectedValues[0] % room_count;
    let remainingChild7to12 = selectedValues[1] % room_count;

    for (let i = 0; i < room_count; i++) {
      const configuration = {
        adults: adultsPerRoom + (remainingAdults > 0 ? 1 : 0),
        child_0_to_6: child0to6PerRoom + (remainingChild0to6 > 0 ? 1 : 0),
        child_7_to_12: child7to12PerRoom + (remainingChild7to12 > 0 ? 1 : 0),
      };

      roomConfigurations.push(configuration);

      remainingAdults > 0 && remainingAdults--;
      remainingChild0to6 > 0 && remainingChild0to6--;
      remainingChild7to12 > 0 && remainingChild7to12--;
    }

    return constructRoomsInfo(roomConfigurations);
  };
  let count = 0;
  const handleUpdate = () => {
    const updatedValues = selectedValues.map((value) => parseInt(value, 10));
    const updatedRoomInfo = calculateRoomInfo();
    dispatch(updateRoomInfo(updatedRoomInfo));
    handleClose();
   

    console.log("Updated parmas_for_api:", parmas_for_api);
    console.log(updatedValues);
    console.log(updatedRoomInfo);
    const requestData = {
      id: parmas_for_api?.id,
      no_of_adults,
      no_of_children,
      room_count,
      from_date,
      to_date,
      room_info: updatedRoomInfo,
    };
    console.log(requestData, "comming from request update");
    dispatch(getHotel(requestData));
    // delayFunc();
  };

  useEffect(() => {
    setChildCount(parmas_for_api?.no_of_children);
    console.log(parmas_for_api);
  }, [parmas_for_api?.no_of_children]);
  useEffect(() => {
    //  getHotelById()
    console.log(
      id,
      no_of_adults,
      no_of_children,
      room_count,
      from_date,
      to_date,
      room_info,
      "fromtotal guest"
    );

    // if (no_of_children > 0) {
    //   setToggleDisplay(true);
    // }
    // if (parmas_for_api?.no_of_children > 0) {
    //   alert("better pricde")
    // } else {
    //   setToggleDisplay(false);
    // }
  

    // getHotelById(
    //   {
    //     id: 46,
    //     no_of_adults,
    //     no_of_children,
    //     room_count,
    //     from_date,
    //     to_date,
    //     room_info,
    //   },
    //   setHotelData
    // );
    // setParamsHotel({
    //   id,
    //   no_of_adults,
    //   no_of_children,
    //   room_count,
    //   from_date,
    //   to_date,
    //   room_info,
    // });
  }, [no_of_adults, room_count, no_of_children, room_info, from_date, to_date]);
  console.log(hotelData);
  console.log(paramsHotel);
  // useEffect(() => {
  //   dispatch(getHotel(paramsHotel));
  // }, [paramsHotel]);

  return (
    <>
      <div className="guest-container" onClick={() => setToggleDisplay(true)}>
        <div className="guest-icon">
          <PeopleIcon style={{ fontSize: "23px", marginRight: "8px" }} />
          <p style={{ marginBottom: "4px" }}>Guest</p>
        </div>
        <div className="guest-text">
          <div className="text-guest">
            <p>{parmas_for_api.no_of_adults} Adults,</p>
            <p>{parmas_for_api.no_of_children} Children</p>
          </div>
        </div>
      </div>
      <div
        className={`container-update-count ${
          toggleDisplay ? "toggle-display" : ""
        }`}
      >
        <div className="inside-wrapper">
          <div className="select-text-button">
            <p style={{ color: "rgb(1, 80, 157)" }}>Select Guests</p>
            <CloseIcon onClick={handleClose} style={{ cursor: "pointer" }} />
          </div>
          <div className="adult-count">
            <div>
              <p className="p-guest-style">Adults</p>
            </div>
            <div className="div-inc-dec">
              <RemoveIcon
                onClick={handleDecrementAdults}
                style={{ cursor: "pointer" }}
              />
              <p>{parmas_for_api.no_of_adults}</p>
              <AddIcon
                onClick={handleIncrementAdults}
                style={{ cursor: "pointer" }}
              />
            </div>
          </div>
          <div className="child-count-container">
            <div className="adult-count">
              <div>
                <p className="p-guest-style">Children</p>
              </div>
              <div className="div-inc-dec">
                <RemoveIcon onClick={handleDecrementChildren} />
                <p>{parmas_for_api.no_of_children}</p>
                <AddIcon onClick={handleIncrementChildren} />
              </div>
            </div>
            <div
              className={
                parmas_for_api?.no_of_children > 0 ? "showed" : "hidden"
              }
            >
              <p
                style={{
                  fontSize: "14px",
                  background: "red",
                  color: "#fff",
                  letterSpacing: "0.5px",
                  borderRadius: "4px",
                  padding: "5px 1px",
                }}
              >
                {parmas_for_api?.no_of_children > 1
                  ? `Please select the correct no of children's .`
                  : `Please select the correct no of children .`}
              </p>
              {dataAgeText.map((item, i) => (
                <div className="main-input-container" key={i}>
                  <p className="p-guest-style">{item.title1}</p>
                  <p className="p-guest-style" style={{ fontSize: "12px" }}>
                    {item.title2}
                  </p>
                  <select
                    style={{ width: "80px", padding: "5px", fontSize: "16px" }}
                    id="dropdown"
                    value={selectedValues[i]}
                    onChange={(e) => handleAgeSelect(i, e.target.value)}
                    className="box"
                  >
                    <option style={{ fontSize: "16px" }} value={0}>
                      {" "}
                      0{" "}
                    </option>
                    {[...Array(parmas_for_api?.no_of_children)].map(
                      (_, index) => (
                        <option key={index + 1} value={index + 1}>
                          {index + 1}
                        </option>
                      )
                    )}
                  </select>
                </div>
              ))}
            </div>
          </div>
          {/* 
          <button
            // className="hero-update-btn"

          >
            Update
          </button> */}

          {/* <button
            className={`hero-update-btn ${
              (parmas_for_api?.no_of_children === 0 &&
                parmas_for_api?.no_of_adults > 0) ||
              (parmas_for_api?.no_of_children > 0 &&
                selectedValues.reduce((acc, value) => acc + value, 0) ===
                  parmas_for_api?.no_of_children)
                ? ""
                : "disabled"
            }`}
            onClick={handleUpdate}
            disabled={
              (parmas_for_api?.no_of_children === 0 &&
                parmas_for_api?.no_of_adults > 0) ||
              (parmas_for_api?.no_of_children > 0 &&
                selectedValues.reduce((acc, value) => acc + value, 0) ===
                  parmas_for_api?.no_of_children)
                ? false
                : true
            }
          >
            Update
          </button> */}

          <button
            className={`hero-update-btn${
              !(
                (parmas_for_api?.no_of_children === 0 &&
                  parmas_for_api?.no_of_adults > 0) ||
                (parmas_for_api?.no_of_children > 0 &&
                  selectedValues.some((value) => value > 0) &&
                  selectedValues.reduce((acc, value) => acc + value, 0) ===
                    parmas_for_api?.no_of_children)
              )
                ? " disabled"
                : ""
            }`}
            onClick={() => {
              if (
                // Check if there are no children and at least one adult is selected
                (parmas_for_api?.no_of_children === 0 &&
                  parmas_for_api?.no_of_adults > 0) ||
                // Check if there are children, ages are selected, and the total selected children match the expected count
                (parmas_for_api?.no_of_children > 0 &&
                  selectedValues.some((value) => value > 0) &&
                  selectedValues.reduce((acc, value) => acc + value, 0) ===
                    parmas_for_api?.no_of_children)
              ) {
                // Perform your update logic here
                handleUpdate();
              } else {
                alert("Please select the correct number of guests.");
              }
            }}
            disabled={
              // Disable the button if the conditions for updating are not met
              !(
                (parmas_for_api?.no_of_children === 0 &&
                  parmas_for_api?.no_of_adults > 0) ||
                (parmas_for_api?.no_of_children > 0 &&
                  selectedValues.some((value) => value > 0) &&
                  selectedValues.reduce((acc, value) => acc + value, 0) ===
                    parmas_for_api?.no_of_children)
              )
            }
          >
            Update
          </button>
        </div>
      </div>
    </>
  );
};

export default GuestCount;
