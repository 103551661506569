import axios from "axios";

const BASE_URL = "https://api.ratebotai.com:8443";
const API_INSERT_BOOKING_DATA = BASE_URL + "/insert_booking_data"

export const insertBookingData = async (params={},func,bookingInfo) => {
    console.log(params,func,"from api");
    const res = await axios.post(API_INSERT_BOOKING_DATA,params);
    console.log(res.data,bookingInfo,"comming from res[");
    func(res.data.data)
}