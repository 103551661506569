import React, { useState, useRef, useEffect } from "react";
import Divider from "@mui/material/Divider";
import KeyboardArrowDownSharpIcon from "@mui/icons-material/KeyboardArrowDownSharp";
import KeyboardArrowUpSharpIcon from "@mui/icons-material/KeyboardArrowUpSharp";
import { useContext } from "react";
import { HotelContext } from "../../Context/HotelContext";
// import { LoginContext } from "../../Contexts/LoginContext";
// import defaultImage from "../../media/images/defaultImage.jpg"
export default function BookingDetails(props) {
  const { bookInfo } = props;
  const { selectedRoomInfo } = useContext(HotelContext);
  console.log(selectedRoomInfo, "comming from voucher");
  //   const {searchParams} = useContext(LoginContext);
  const [isArrowDown1, setIsArrowDown1] = useState(true);
  const [isArrowDown2, setIsArrowDown2] = useState(true);
  const [todayDate, setTodayDate] = useState("");

  //   function formatDateWithDay(dateString) {
  //     console.log(dateString);
  //     const options = { weekday: 'short', day: '2-digit', month: 'short', year: 'numeric' };
  //     const date = new Date(dateString);
  //     const formattedDate = new Intl.DateTimeFormat('en-US', options).format(date);
  //     setTodayDate(formattedDate);
  //   }

  //   useEffect(() => {
  //     formatDateWithDay(bookInfo?.from_date)
  //   },[])
  console.log(bookInfo, "my booking info object");
  function displayTableBody() {
    setIsArrowDown1(!isArrowDown1);
    const tableBody = document.getElementById("table-body");
    const tableFooter = document.getElementById("tfooter");
    if (tableBody.style.display === "none" || tableBody.style.display === "") {
      tableBody.style.display = "contents";
    } else {
      tableBody.style.display = "none";
    }
    if (
      tableFooter.style.display === "none" ||
      tableFooter.style.display === ""
    ) {
      tableFooter.style.display = "contents";
    } else {
      tableFooter.style.display = "none";
    }
  }
  function hideDiscountSection() {
    setIsArrowDown2(!isArrowDown2);
    const tableBody = document.getElementsByClassName("discount");
    for (let element of tableBody) {
      if (element.style.display === "none") {
        element.style.display = "";
      } else {
        element.style.display = "none";
      }
    }
  }

  
  function formatTimestampToIndianTime(timestamp) {
    const options = {
      timeZone: "Asia/Kolkata",
      year: "numeric",
      month: "short",
      day: "numeric",
      weekday: "short",
      hour: "2-digit",
      minute: "2-digit",
      timeZoneName: "short",
    };
  
    const istOffset = 5.5 * 60 * 60 * 1000; // 5 hours 30 minutes in milliseconds
    const istTime = new Date(timestamp - istOffset);
  
    return istTime.toLocaleString("en-IN", options);
  }
 
  const created_datetime = bookInfo?.created_datetime; // Replace with your timestamp
  const currentIndianTime = formatTimestampToIndianTime(created_datetime);
  console.log(currentIndianTime);
  

  return (
    <div className="book-details-container column-flex">
      <div className="header-section-row">
        <div className="left">
          <h2>Booking Id</h2>
          <div style={{ fontSize: "20px", lineHeight: "2.5" }}>
            {bookInfo.booking_id}
          </div>
        </div>
        <div className="right">
          <p>
            Booked by : {" "}
            <strong>
              {bookInfo.first_name} {bookInfo.last_name}
            </strong>{" "}<br />
            {currentIndianTime}
          </p>
        </div>
      </div>
      <Divider variant="middle" />
      <div className="hotel-details-section-row">
        <div className="left-column-flex">
          <div style={{ marginTop: "15px" }}>
            <h3>{bookInfo?.hotel_name || bookInfo?.hotelname}</h3>
            <p style={{ fontSize: "14px", marginTop: "15px" }}>
              {bookInfo?.address}
            </p>
          </div>
          {/* <div>
            <h3>Hotel Direction</h3>
            <p>{bookInfo?.hotel_direction}</p>
          </div>
          <div>
            <h3>Land Mark</h3>
            <p>{bookInfo?.land_mark}</p>
          </div> */}
        </div>
        <div className="right" style={{ padding: "1rem" }}>
          <img
            className="voucher-img-htl"
            src={
              bookInfo?.hotel_gallery
                ? bookInfo.hotel_gallery[0].file
                : selectedRoomInfo?.rooms_types_gallery[0].file
            }
            alt={"hotel image"}
          ></img>
        </div>
      </div>
      <Divider variant="middle" />
      <div className="user-details-section-row-flex">
        <div className="left-column-flex-des">
          <div className="row-flex">
            <div>
              <p>Primary Guest</p>
              <p>
                <strong>{bookInfo.first_name}</strong>
              </p>
            </div>

            <div className="checkintime-voucher">
              <div>
                <p>Check In</p>
                <p>
                  <strong>{bookInfo.from_date}</strong>
                </p>
              </div>
              <div>
                <p>Check In Time</p>
                <p>
                  <strong>{"12:00 PM"}</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="row-flex">
            <div>
              <p>Mobile Number</p>
              <p>
                <strong>{bookInfo.mobile_number}</strong>
              </p>
            </div>

            <div className="checkintime-voucher">
              <div>
                <p>Check Out</p>
                <p>
                  <strong>{bookInfo.to_date}</strong>
                </p>
              </div>
              <div>
                <p>Check Out Time</p>
                <p>
                  <strong>{"11:00 AM"}</strong>
                </p>
              </div>
            </div>
          </div>
          <div className="row-flex">
            <div>
              <p>Email Address</p>
              <p>
                <strong>{bookInfo.email}</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="right column-flex">
          <div className="row-flex">
            <div>
              <h2 style={{ textAlign: "right" }}>
                {bookInfo.no_of_nights} Night
              </h2>
            </div>
          </div>
          <div className="row-flex">
            <div>
              <p style={{ textAlign: "right" }}>
                {bookInfo.no_of_adults + bookInfo.no_of_children} Guests
              </p>
            </div>
            <div>
              <p style={{ textAlign: "right" }}>
                {bookInfo?.no_of_rooms || 1} Room
              </p>
              <p style={{ textAlign: "right" }}>
                <strong>
                  {bookInfo?.room_booking?.length
                    ? bookInfo?.room_booking[0].room_title
                    : "Deluxe"}
                </strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <Divider variant="middle" />
      <div className="payment-details-main-container">
        <div
          style={{ textAlign: "justify" }}
          className="payment-details section column-flex"
        >
          <div>
            <h3 style={{ textAlign: "center", marginLeft: "-10%" }}>
              Payment Details
            </h3>
          </div>
          <div style={{ paddingLeft: "0px" }}>
            <table className="shadow">
              {/* <thead onClick={() => displayTableBody()}>
              <tr className="table-row">
                <td className="left-cell" colSpan={2}>
                  Total booking amount
                </td>
                <th className="right-cell">
                  ₹
                  {bookInfo.total_without_tax +
                    bookInfo.total_services_amount +
                    bookInfo.tax_amount -
                    (bookInfo.coupon_value + bookInfo.discount_value)}{" "}
                  <span>
                    {isArrowDown1 ? (
                      <KeyboardArrowDownSharpIcon />
                    ) : (
                      <KeyboardArrowUpSharpIcon />
                    )}
                  </span>
                </th>
              </tr>
            </thead> */}
              <tbody id="table-body">
                <tr className="table-row">
                  <td className="left-cell">Room charges for</td>
                  <td className="left-cell">
                    {bookInfo?.no_of_rooms || 1} Room x {bookInfo.no_of_nights}{" "}
                    Night
                  </td>
                  <th style={{ textAlign: "right" }} className="right-cell">
                    ₹
                    {bookInfo.total_without_tax +
                      bookInfo?.room_booking?.[0]?.coupon_amount}
                  </th>
                </tr>
                {/* <tr className="table-row" onClick={() => hideDiscountSection()}>
                <td className="left-cell" colSpan={2}>
                  Discounts{" "}
                  <span>
                    {" "}
                    {isArrowDown2 ? (
                      <KeyboardArrowDownSharpIcon />
                    ) : (
                      <KeyboardArrowUpSharpIcon />
                    )}{" "}
                  </span>
                </td>
                <th className="right-cell">
                  -₹{bookInfo.coupon_value + bookInfo.discount_value}
                </th>
              </tr>
              <tr className="table-row discount">
                <td className="left-cell" colSpan={2}>
                  Coupon: FINDWB
                </td>
                <th className="right-cell">-₹{bookInfo.coupon_value}</th>
              </tr>
              <tr className="table-row discount">
                <td className="left-cell" colSpan={2}>
                  WB Discount
                </td>
                <th className="right-cell">-₹{bookInfo.discount_value}</th>
              </tr> */}
                <tr className="table-row">
                  <td className="left-cell" colSpan={2}>
                    Coupon:{bookInfo?.coupon_value}
                  </td>
                  <th style={{ textAlign: "right" }} className="right-cell">
                    -₹{bookInfo?.room_booking?.[0]?.coupon_amount}
                  </th>
                </tr>
                <tr className="table-row">
                  <td className="left-cell" colSpan={2}>
                    Service & Tax
                  </td>
                  <th style={{ textAlign: "right" }} className="right-cell">
                    ₹{bookInfo.total_services_amount + bookInfo.tax_amount}
                  </th>
                </tr>
              </tbody>
              <tfoot id="tfooter">
                <tr className="table-row table-footer">
                  <td className="left-cell" colSpan={2}>
                    <h3>Total booking amount</h3>
                  </td>
                  <th style={{ textAlign: "right" }} className="right-cell">
                    <h2>₹{bookInfo?.total_sale_amount}</h2>
                  </th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
      </div>
      {/* <div className="help section row-flex">
        <div className="column-flex">
          <div>
            <h2>Things To Know</h2>
          </div>
        </div>
        <div className="column-flex">
          <div>
            <p>
              Something not right?<a href="#"> Chat with us</a> for help.{" "}
            </p>
          </div>
          <div>
            <p>
              <a href="#">Read WB's Terms and Condition</a>
            </p>
          </div>
          <div>
            <p>
              <a href="#">Read WB's Guest Policy</a>
            </p>
          </div>
        </div>
      </div> */}
    </div>
  );
}
