import React, { useContext, useState } from "react";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import PayHotel from "./PayHotel";
import PayPartial from "./PayPartial";
import "./payhotelpartial.css";
import { HotelContext } from "../../Context/HotelContext";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

const PayHotelPartial = () => {
  const { partial_tax_gst, partialamountpercentage } = useSelector(
    (store) => store?.reducerHotel?.hotel?.hotel_data || {}
  );
  const { payHotel, setPayHotel, setToggleText, toggleText } =
    useContext(HotelContext);
  //   const [payHotel, setPayHotel] = useState(false);
  //   const [toggleText, setToggleText] = useState(true);

  useEffect(() => {
    if (partialamountpercentage === 0) {
      console.log(partialamountpercentage);
      console.log("from if partial effect");
      setPayHotel(true);
    } else {
      console.log(partialamountpercentage);
      console.log("from else partial effect");
      setPayHotel(false);
    }
  }, []);
  const handleClick = (id) => {
    if (id === "at") {
      setToggleText(true);
    } else {
      setToggleText(false);
    }
  };
  const handleText = () => {
    const payText =
      toggleText === payHotel
        ? `We will confirm your Stay without any charge. Pay directly at the hotel during your stay.`
        : ` Payment Options:Partial Payment: Confirm your stay by paying a portion today. Pay the      remaining balance at the hotel.
        Full Payment: Secure your reservation by making the full payment today.`;
    return payText;
  };

  return (
    <div className="booking-pay-hotel-partial">
      <div className="pay-button pay-hotel-btn">
        <button
          onClick={() => handleClick("at")}
          style={{
            boxShadow: toggleText ? "rgba(0, 0, 0, 0.35) 0px 5px 15px" : null,
            backgroundColor: toggleText ? "#ffffff" : null,
            border: toggleText ? "1px solid grey" : null,
            color: toggleText ? "rgb(1, 80, 157)" : null,
            fontWeight: toggleText ? "600" :null,
            display: "block",
          }}
        >
          <span style={{ display: "flex", alignItems: "flex-end",fontSize:"15px" }}>
            {" "}
            Pay At Hotel
            {toggleText ? (
              <CheckCircleIcon
                style={{
                  margin: "4px 0 0 10px",
                  fontSize: "16px",
                  color: "green",
                }}
              />
            ) : (
              ""
            )}
          </span>
        </button>
        <button
          onClick={() => handleClick("now")}
          style={{
            boxShadow: toggleText ? null : "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            fontWeight: toggleText ? null : "600",
            backgroundColor: toggleText ? null : "#ffffff",
            border: toggleText ? null : "1px solid grey",
            color: toggleText ? null : "rgb(1, 80, 157)",
          }}
        >
           <span style={{ display: "flex", alignItems: "flex-end" ,fontSize:"15px"}}>
          Pay Now
          {toggleText ? (
            ""
          ) : (
            <CheckCircleIcon
              style={{
                margin: "4px 0 0 10px",
                fontSize: "16px",
                color: "green",
              }}
            />
          )}
          </span>
        </button>
      </div>
      <div>
        {toggleText ? (
          <PayHotel payHotel={payHotel} />
        ) : (
          <PayPartial
            payHotel={payHotel}
            partialamountpercentage={partialamountpercentage}
            partial_tax_gst={partial_tax_gst}
          />
        )}
      </div>
    </div>
  );
};

export default PayHotelPartial;
