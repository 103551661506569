import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { getHotelFooter, getLogoFooter } from "../../api/getHotelFooter.api";
import "./foooter.css";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
import PhoneIcon from "@mui/icons-material/Phone";
import GoogleIcon from "@mui/icons-material/Google";
import EmailIcon from "@mui/icons-material/Email";

const Footer = () => {
  const [data, setData] = useState([]);
  const [logoData, setLogoData] = useState([]);
  const hotel = useSelector((store) => store?.urlReducer?.selected_hotel);
  const hotelDetails = useSelector((store) => store?.reducerHotel);

  useEffect(() => {
    console.log(data, "comming from footer componenet");
    getHotelFooter({ hotel_id: hotel?.hotel_id }, setData);
    getLogoFooter({ hotel_id: hotel?.hotel_id }, setLogoData);
  }, []);

  
  let text = hotelDetails?.hotel?.hotel_data?.hotelname;
  let withoutIdHotelname = text?.slice(6, text?.length);


  return (
    <div>
      <div className="footer-main-container-link">
        <div className="hotel-desc-addres-cont">
          <p>{withoutIdHotelname}</p>
          <p>
            {hotelDetails?.hotel?.hotel_data?.completeaddress} -{" "}
            {hotelDetails?.hotel?.hotel_data?.postalzipcode}
          </p>

          <p>
            {"Tel: "} {hotelDetails?.hotel?.hotel_data?.mobilenumber}
          </p>
          <p> {"Email: "} {hotelDetails?.hotel?.hotel_data?.email}</p>
        </div>
        <div className="footer-link-rout-nepal-website">
          {data?.map((item) => (
            <p>
              <a
                style={{ textDecoration: "none", color: "#ffffff" }}
                target="blank"
                href={item?.link}
              >
                {item?.title}
              </a>
            </p>
          ))}
        </div>
        <div className="social-media-wrapper">
            <p>Find us on</p>
          <InstagramIcon className="icon-style-social"/>
          <FacebookIcon className="icon-style-social"/>
          <GoogleIcon className="icon-style-social" />
          <EmailIcon className="icon-style-social"/>
        </div>
      </div>
      <div className="brand-logo-container">
        <div className="brand-text">
          <p>OUR BRAND</p>
        </div>

        <div className="brand-image-cont">
          {logoData?.map((item) => (
            <img
              src={item?.file}
              alt={item?.lable}
              width="150px"
              height="70px"
            />
          ))}
        </div>
      </div>
      <div className="copy-right-container">
        <p>
          Copyright © 2023 {withoutIdHotelname} , powered by{" "}
          <a
            href="https://ratebotai.com/"
            target="blank"
            style={{ textDecoration: "none", color: "rgb(5, 219, 92)" }}
          >
            RateBotAi
          </a>
        </p>
      </div>
    </div>
  );
};

export default Footer;
