import React from "react";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import "./filterrooms.css";
import { Input } from "@mui/material";
import Calendar from "../CheckInCheckOutCalendar/Calendar";
import GuestCount from "../GuestCount/GuestCount";
import UpdateButton from "../UpdateButton/UpdateButton";

const FilterRooms = () => {
  const [age, setAge] = React.useState("");

  const handleChange = (event) => {
    setAge(event.target.value);
  };
  return (
    <div>
      <div className="filter-wrapper">
        <div className="calender-filter-container">
          <Calendar />
          {/* <Calendar handleDateRangeUpdate={handleUpdate} /> */}

        </div>
        <div className="guest-count-container">
          <GuestCount />
        </div>
        <div className="filter-rooms">
          <p className="p-filter-style">View Rooms By</p>
          <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
            <InputLabel
              id="demo-select-small-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              Filter
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={age}
              label="Filter"
              onChange={handleChange}
              style={{ height: "30px", backgroundColor: "#fff" }}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              <MenuItem value={"Rooms"}>Rooms</MenuItem>
              <MenuItem value={"Rates"}>Rates</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div className="filter-rooms">
          <p className="p-filter-style">Sort By</p>
          <FormControl sx={{ m: 1, minWidth: 100 }} size="small">
            <InputLabel
              id="demo-select-small-label"
              style={{ fontSize: "12px", fontWeight: "600" }}
            >
              Filter
            </InputLabel>
            <Select
              labelId="demo-select-small-label"
              id="demo-select-small"
              value={age}
              label="Filter"
              onChange={handleChange}
              style={{ height: "30px", backgroundColor: "#fff" }}
            >
              <MenuItem value="">
                <em>Recommended</em>
              </MenuItem>
              <MenuItem value={"Rooms"}>Lowest Price</MenuItem>
              <MenuItem value={"Rates"}>Highest Price</MenuItem>
              
            </Select>
          </FormControl>
        </div>
      </div>
     {/* <UpdateButton /> */}
    </div>
  );
};

export default FilterRooms;
