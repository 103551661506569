import React from "react";
import GrossTotalCard from "../../Components/GrossTotalCard/GrossTotalCard";
import "./booking.css"
import BookingUserDetails from "../../Components/BookingUserDetails/BookingUserDetails";
import GrossTotalCardForBooking from "../../Components/GrossTotalCard/GrossTotalCardForBooking";

const BookingConfirmationPage = () => {
  return (
    <>
      <div className="select-room-text select-room-text-t"style={{margin:"auto",marginTop: "5rem" }}>Booking Confirmation</div>

      <div className="booking-left-right-cont">
        <div className="booking-left-cont">
            <BookingUserDetails />
        </div>
        <div className="booking-right-cont">
          <GrossTotalCardForBooking /> 
        </div>
      </div>
    </>
  );
};

export default BookingConfirmationPage;
