import React, { useContext } from "react";
import { HotelContext } from "../../Context/HotelContext";
import { insertBookingInfoObj } from "./insertBookingInfoObj";
import { LoginContext } from "../../Context/UserLoginContext";
import { useSelector } from "react-redux";
import { insertBookingData } from "../../api/getBookingConfirmation.api";
import { useNavigate } from "react-router-dom";
import "./payhotelpartial.css"

const PayHotel = ({ payHotel }) => {
  const { selectedRoomInfo, setSelectedRoomInfo, setBookingInfo, bookingInfo } =
    useContext(HotelContext);
  const { userLoginData } = useContext(LoginContext);
  const singleHotelData = useSelector(
    (store) => store?.reducerHotel?.hotel?.hotel_data || {}
  );
  const enquiredData = useSelector(
    (store) => store?.urlReducer?.parmas_for_api || {}
  );
  console.log(enquiredData, "this is from params for api payhotel");
  const navigate = useNavigate();
  const handleBooking = () => {
    const params = insertBookingInfoObj(
      selectedRoomInfo,
      userLoginData,
      singleHotelData,
      enquiredData
    );
    insertBookingData(params, setBookingInfo, bookingInfo);
    navigate("/voucher");
  };
  return (
    <div className="payhotel-container">
      {payHotel ? (
        <p>
           <strong>No payment needed today.</strong>
          <br />
          We will confirm your Stay without any charge. Pay directly at the
          hotel during your stay.
        </p>
      ) : (
        <p>
          <strong> Payment required today.</strong>
          <br />
          We will confirm your Stay with a payment. Pay in advance to secure
          your reservation before your stay.
        </p>
      )}
      <div className="pay-hotel-btn">
        <button
          onClick={handleBooking}
          disabled={!payHotel}
          style={{ textDecoration: payHotel ? "none" : "line-through" }}
        >
          Book Now
          {/* <p style={{ textDecoration: payHotel ? "none" : "line-through" }}> Book Now</p> */}
        </button>
      </div>
    </div>
  );
};

export default PayHotel;
