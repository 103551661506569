import React, { useContext, useEffect, useState } from "react";
import "./roomcard.css";
import RoomInfoModal from "../RoomInfoModal/RoomInfoModal";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import Bookbutton from "../BookButton/Bookbutton";
import { useSelector } from "react-redux";
import PersonIcon from "@mui/icons-material/Person";
import { HotelContext } from "../../Context/HotelContext";
import SimpleImageSlider from "react-simple-image-slider";
import CloseIcon from "@mui/icons-material/Close";
import { faIR } from "date-fns/locale";

const RoomCard = (props) => {
  const { item, id, singleHotelData } = props;
  const { rooms_types_gallery, roomtype, rooms } = item;
  const { maxpeople, amenitiesidstored } = rooms[0];
  const { hoteldescription, rating_json, booking_info, hotel_gallery } =
    singleHotelData;
  const [selectedButton, setSelectedButton] = useState(0);
  const [toggle, setToggle] = useState(false);
  const count_room = useSelector((store) => store?.urlReducer?.room_count);
  const {
    selectedRoom,
    setSelectedRoom,
    setSelectedRoomInfo,
    selectedRoomInfo,
  } = useContext(HotelContext);
  const [toggleGallery, setToggleGallery] = useState(false);

  let arrPeople = [0, 0, 0];
  useEffect(() => {
    if (id === 1) {
      // setSelectedButton(buttonId);
      // setSelectedButton((prevSelectedButton) =>
      //   prevSelectedButton === buttonId ? null : buttonId
      // );
      setSelectedRoom(rooms[0]);
      setSelectedRoomInfo(item);
    }
  }, []);

  const handleSelect = (buttonId) => {
    setSelectedButton(buttonId);
    // setSelectedButton((prevSelectedButton) =>
    //   prevSelectedButton === buttonId ? null : buttonId
    // );
    setSelectedRoom(rooms[0]);
    setSelectedRoomInfo(item);
    console.log(selectedRoomInfo, "selected romm info");
  };
  const handleToggle = () => {
    setToggle(!toggle);
  };
  const handleGalery = (valueT) => {
    console.log(valueT, "from handle galery");
    setToggleGallery(valueT);
  };
  // useEffect(() => {
  //   console.log(selectedRoom,"from selected room effect");
  // },[selectedRoom])

  return (
    <div className="room-card-container">
      <div className="select-room-text">
        <p>{roomtype}</p>
      </div>
      <div className="room-card-left-right">
        <div className="room-card-left">
          <div className="room-image img-hover-zoom--slowmo img7">
            <img
              src={rooms_types_gallery[0]?.file}
              alt={rooms_types_gallery[0]?.lable}
            />
          </div>
        </div>
        <div className="room-card-middle">
          <div>
            <p>Sleeps</p>
            <p>
              {maxpeople == 1
                ? arrPeople?.map(() => <PersonIcon />)
                : Array.apply(null, Array(+maxpeople))?.map(() => (
                    <PersonIcon />
                  ))}
            </p>
          </div>
          <div>
            <RoomInfoModal
              key={id}
              amenitiesidstored={amenitiesidstored}
              rooms_types_gallery={rooms_types_gallery}
              roomtype={roomtype}
              maxpeople={maxpeople}
              rating_json={rating_json}
              booking_info={booking_info}
              hotel_gallery={hotel_gallery}
            />
          </div>
          <div>
            <div onClick={() => handleGalery(true)}>
              <p>Photos</p>
            </div>
            <div className="modal-gallery-cont-photo">
              <div className="modal-gallery-wrapper">
                {toggleGallery ? (
                  <SimpleImageSlider
                    key={2 + Math.random()}
                    width={446}
                    height={264}
                    images={hotel_gallery?.map((item) => {
                      if (item) {
                        return { url: item.file };
                      }
                    })}
                    showBullets={true}
                    showNavs={true}
                  />
                ) : null}
              </div>
            </div>
          </div>
          {/* <div className="modal-gallery-close">
            <CloseIcon
              style={{ fontSize: "95px", cursor: "pointer", color: "blue" }}
              onClick={() => handleGalery(false)}
            />
          </div> */}
        </div>
        <div className="room-card-right">
          {/* <div className="room-card-title">
            <p className="p-text-room1">{roomtype}</p>
          </div> */}
          <div className="room-card-details">
            {/* <p className="p-text-room-desc" style={{marginBottom:"5px"}}>Sleeps {maxpeople}</p>
            <p className="p-text-room-desc">
              {hoteldescription?.length > 1
                ? hoteldescription
                : `${roomtype} are well furnished to the very highest standards of luxury with modern Amenities.`}
            </p> */}
            <div className="room-card-price-cont text-color-check">
              <p style={{ fontSize: "18px", color: "rgb(1, 80, 157)" }}>
                <CurrencyRupeeIcon
                  style={{ fontSize: "16px", paddingTop: "1px" }}
                />
                {rooms[0]?.room_price_date_wise?.reduce(
                  (acc, curr) => acc + curr.price,
                  0
                )}
              </p>
              <p>
                {selectedRoom?.room_price_date_wise?.reduce(
                  (acc, curr) => curr?.no_days + acc,
                  0
                ) == 1
                  ? "Per Night"
                  : selectedRoom?.room_price_date_wise?.reduce(
                      (acc, curr) => curr?.no_days + acc,
                      0
                    ) + "  Nights"}
              </p>
              <p>Excluding Taxes & Fees</p>
              <div className="card-select-button">
                <Bookbutton
                  key={id}
                  handleSelect={handleSelect}
                  id={id}
                  selectedButton={selectedButton}
                  handleToggle={handleToggle}
                  roomtype={roomtype}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RoomCard;
