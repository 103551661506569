import { calculateTotalPriceORTax } from "../../utilFunction/calculatePriceOrTax";

export const insertBookingInfoObj = (
  selectedRoom,
  userInfoDetails,
  singleHotel = {},
  enquiredData = {},
  callingFrom = "payhotel",
  
  // tax_info = [12]
) => {
  const { from_date, no_of_adults, no_of_children, to_date, id } = enquiredData;
  const { partialamountpercentage, tax_info } = singleHotel;
  const priceData = JSON.parse(localStorage.getItem('priceData'));
  console.log(id, "from enqired data hotel id");
  console.log(priceData,"before booking info obj");
  const total_without_tax = +priceData?.roomPrice;

  const tax_amount = +priceData?.tax;
  const totalWithTax = +priceData?.total;
  const paid_amount = callingFrom === "payhotel" ? 0 : 0;
  const partial_paid_amount = +priceData?.partialamountwithtax;
  const no_of_nights = selectedRoom?.rooms[0].room_price_date_wise?.reduce(
    (acc, curr) => curr?.no_days + acc,
    0
  );
  if (callingFrom === "payhotel") {
    const selectedBookingRoomInfo = {
      ...userInfoDetails,
      from_date,
      no_of_nights,
      no_of_pax: no_of_adults + no_of_children,
      no_of_adults,
      no_of_children,
      to_date,
      mobile_number: userInfoDetails?.phone_number,
      hotel_id: singleHotel?.id,
      minimum_advance: 0,
      gross_amount: total_without_tax + tax_amount,
      paid_amount,
      balance_amount: total_without_tax + tax_amount,
      total_without_tax,
      tax_amount,
      total_sale_amount: totalWithTax,
      created_by: userInfoDetails?.customer_id,
      tax_id: singleHotel?.tax_id,
      room_assinged_adult_info: selectedRoom?.room_assinged_adult_info,
      room_booking: [
        {
          ...selectedRoom?.room_assinged_adult_info[0],
          total_sale_amount: totalWithTax,
          discount_amount: 0,
          coupon_amount: +priceData?.couponAmount,
          coupon_id: +priceData?.coupon_id,
          coupon_value: priceData?.coupon_code,
          coupon_type: priceData?.coupontype,
          coupon_discount_amount: +priceData?.couponAmount,
          total_without_tax,
          tax_amount,
          gross_amount: total_without_tax + tax_amount,
        },
      ],
      booking_service: [],
      // hard coded for now
      discount_id: 1,
      discount_value: 0,
      discount_amount: 0,
      discount_type: "percentage",
      coupon_id: +priceData?.coupon_id,
      coupon_value: priceData?.coupon_code,
      coupon_type: priceData?.coupontype,
      coupon_discount_amount: +priceData?.couponAmount,
      country: "India",
      transaction_status: "pending",
      booking_status: "confirmed",
    };
    //   console.log(selectedRoom, "from selectedRoom");
    //   console.log(userInfoDetails, "userinfo");
    //   console.log(singleHotel, "singlehotel");
    //   console.log(enquiredData, "enquired data");
    // console.log(selectedBookingRoomInfo, "new boooking id");
    return selectedBookingRoomInfo;
  } else if (callingFrom === "payPartial") {
    // console.log("coming from paypartial");
    console.log(partial_paid_amount, "after ");
    const selectedBookingRoomInfo = {
      ...userInfoDetails,
      from_date,
      no_of_nights,
      no_of_pax: no_of_adults + no_of_children,
      no_of_adults,
      no_of_children,
      to_date,
      mobile_number: userInfoDetails?.phone_number,
      hotel_id: singleHotel?.id,
      minimum_advance: 0,
      gross_amount: total_without_tax + tax_amount,
      paid_amount: partial_paid_amount,
      balance_amount: total_without_tax + tax_amount - partial_paid_amount,
      total_without_tax,
      tax_amount,
      total_sale_amount: totalWithTax,
      created_by: userInfoDetails?.customer_id,
      tax_id: singleHotel?.tax_id,
      room_assinged_adult_info: selectedRoom?.room_assinged_adult_info,
      room_booking: [
        {
          ...selectedRoom?.room_assinged_adult_info[0],
          total_sale_amount: totalWithTax,
          discount_amount: 0,
          coupon_amount: +priceData?.couponAmount,
          coupon_id: +priceData?.coupon_id,
          coupon_value: priceData?.coupon_code,
          coupon_type: priceData?.coupontype,
          coupon_discount_amount: +priceData?.couponAmount,
          total_without_tax,
          tax_amount,
          gross_amount: total_without_tax + tax_amount,
        },
      ],
      booking_service: [],
      // hard coded for now
      discount_id: 1,
      discount_value: 0,
      discount_amount: 0,
      discount_type: "percentage",
      coupon_id: +priceData?.coupon_id,
      coupon_value: priceData?.coupon_code,
      coupon_type: priceData?.coupontype,
      coupon_discount_amount: +priceData?.couponAmount,
      country: "India",
      transaction_status: "pending",
      booking_status: "confirmed",
    };
    //   console.log(selectedRoom, "from selectedRoom");
    //   console.log(userInfoDetails, "userinfo");
    //   console.log(singleHotel, "singlehotel");
    //   console.log(enquiredData, "enquired data");
    console.log(selectedBookingRoomInfo, "new boooking id");
    return selectedBookingRoomInfo;
  } else {
    console.log("comming from else payfull");
    const selectedBookingRoomInfo = {
      ...userInfoDetails,
      from_date,
      no_of_nights,
      no_of_pax: no_of_adults + no_of_children,
      no_of_adults,
      no_of_children,
      to_date,
      mobile_number: userInfoDetails?.phone_number,
      hotel_id: singleHotel?.id,
      minimum_advance: 0,
      gross_amount: total_without_tax + tax_amount,
      paid_amount: totalWithTax,
      balance_amount: 0,
      total_without_tax,
      tax_amount,
      total_sale_amount: totalWithTax,
      created_by: userInfoDetails?.customer_id,
      tax_id: singleHotel?.tax_id,
      room_assinged_adult_info: selectedRoom?.room_assinged_adult_info,
      room_booking: [
        {
          ...selectedRoom?.room_assinged_adult_info[0],
          total_sale_amount: totalWithTax,
          discount_amount: 0,
          coupon_amount: +priceData?.couponAmount,
          coupon_id: +priceData?.coupon_id,
          coupon_value: priceData?.coupon_code,
          coupon_type: priceData?.coupontype,
          coupon_discount_amount: +priceData?.couponAmount,
          total_without_tax,
          tax_amount,
          gross_amount: total_without_tax + tax_amount,
        },
      ],
      booking_service: [],
      // hard coded for now
      discount_id: 1,
      discount_value: 0,
      discount_amount: 0,
      discount_type: "percentage",
      coupon_id: +priceData?.coupon_id,
      coupon_value: priceData?.coupon_code,
      coupon_type: priceData?.coupontype,
      coupon_discount_amount: +priceData?.couponAmount,
      country: "India",
      transaction_status: "pending",
      booking_status: "confirmed",
    };
    //   console.log(selectedRoom, "from selectedRoom");
    //   console.log(userInfoDetails, "userinfo");
    //   console.log(singleHotel, "singlehotel");
    //   console.log(enquiredData, "enquired data");
    console.log(selectedBookingRoomInfo, "new boooking id");
    return selectedBookingRoomInfo;
  }
};
