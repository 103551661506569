import React, { useContext, useState } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import { useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import { useNavigate } from "react-router-dom";
import { LoginContext } from "../../Context/UserLoginContext";
import Modal from "@mui/material/Modal";
import "./navbar1.css";

const drawerWidth = 240;

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// export function BasicModal({ open, handleOpen, handleClose }) {
//   // const [open, setOpen] = React.useState(false);
//   // const handleOpen = () => setOpen(true);
//   // const handleClose = () => setOpen(false);

//   return (
//     <div>
//       {/* <Button onClick={handleOpen}>Open modal</Button> */}
//       <Modal
//         open={open}
//         onClose={handleClose}
//         aria-labelledby="modal-modal-title"
//         aria-describedby="modal-modal-description"
//       >
//         <Box sx={style}>
//           <Typography id="modal-modal-title" variant="h6" component="h2">
//             Text in a modal
//           </Typography>
//           <Typography id="modal-modal-description" sx={{ mt: 2 }}>
//             Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
//           </Typography>
//         </Box>
//       </Modal>
//     </div>
//   );
// }

function Navbar(props) {
  const { window } = props;
  const [mobileOpen, setMobileOpen] = React.useState(false);
  const { hotelname, mobilenumber } = useSelector(
    (store) => store?.reducerHotel?.hotel?.hotel_data || {}
  );
  const { userLoginData, isLoggedIn, setUserLoginData, setIsLoggedIn } =
    useContext(LoginContext);
  const hotelDetails = useSelector((store) => store?.reducerHotel);
  // const { hotelname, mobilenumber } = hotelData;
  const navItems = [mobilenumber, "HOTEL INFO", "FIND RESERVATION"];
  const navigate = useNavigate();
  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };
  const [open, setOpen] = React.useState(false);
  const [render, setRender] = useState(true);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  // console.log(hotelData);

  const handleHotelInfo = (item) => {
    console.log(item, "frommodal");
    if (item === "HOTEL INFO") {
      handleOpen();
    }
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <Typography variant="h6">{hotelname?.toUpperCase()}</Typography>
      <Divider />
      <List>
        {navItems.map((item) => (
          <ListItem key={item} disablePadding>
            <ListItemButton sx={{ textAlign: "center" }}>
              <ListItemText primary={item} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  const container =
    window !== undefined ? () => window().document.body : undefined;

  console.log(hotelname);

  // const withoutIdHotelname =(hotelname)=>{
  //   hotelname.slice(6, hotelname.length);
  //   console.log(withoutIdHotelname);
  // }

  // const  withoutIdHotelname = hotelname?.split(" ")?.join("");
  const withoutIdHotelname = hotelname?.substr(6, hotelname?.length);

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        style={{
          backgroundColor: "#ffffff",
          color: "#01509d",
        }}
      >
        <Toolbar>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: "none" } }}
          >
            <MenuIcon />
          </IconButton>
          <Box
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            <IconButton
              color="inherit"
              aria-label="mobile-number"
              sx={{ display: { xs: "block", sm: "none" }, mr: 1 }}
            >
              {/* <PhoneAndroidIcon /> */}
            </IconButton>
            <Typography
              variant="h6"
              component="div"
              sx={{ flexGrow: 0, display: { xs: "none", sm: "block" } }}
            >
              <h4 className="hotel-logo-text">
                {withoutIdHotelname?.toUpperCase()}
              </h4>
            </Typography>
          </Box>
          <Box
            sx={{
              flexGrow: 1,
              display: { xs: "none", sm: "block" },
              color: "#01509d",
            }}
          >
            {navItems.map((item) => (
              <Button
                onClick={() => handleHotelInfo(item)}
                key={item}
                sx={{ color: "#01509d" }}
              >
                {item}
              </Button>
            ))}

            {/* <p>paraaaa</p> */}
            <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            >
              <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                  {withoutIdHotelname}
                </Typography>
                <Typography id="modal-modal-description" sx={{ mt: 2 }}>
                  {/* Duis mollis, est non commodo luctus, nisi erat porttitor
                  ligula. */}
                </Typography>
              </Box>
            </Modal>
          </Box>
          <div
            className={isLoggedIn && render === true ? "card" : ""}
            style={{ padding: "8px" }}
            onClick={() => (isLoggedIn ? "" : navigate("/login"))}
          >
            <Box sx={{ display: { xs: "block", sm: "none" } }}>
              <IconButton color="inherit" aria-label="sign-in">
                {/* <AccountCircleIcon /> */}
              </IconButton>
            </Box>
            <IconButton
              color="inherit"
              aria-label="sign-in"
              sx={{ display: { xs: "none", sm: "block" }, ml: 2 }}
              style={{ display: "flex" }}
              onClick={() => {
                if (!isLoggedIn) {
                  navigate("/login");
                }
              }}
            >
              <AccountCircleIcon />
              <p style={{ fontSize: "12px", padding: "4px" }}>
                {userLoginData?.username ||
                  userLoginData?.phone_number ||
                  "SignUp/SignIn"}
              </p>
            </IconButton>
            {/* .................................................................. */}

            <div className="details">
              <ul className={`dropdown-details-user`}>
                <li className="my-booking">My Booking</li>
                <li>My Profile</li>

                <li
                  onClick={() => {
                    localStorage.clear();
                    //  localStorage.removeItem('userData');
                    setUserLoginData({});
                    setIsLoggedIn(false);
                    setRender(false);
                    navigate("/");
                  }}
                >
                  Logout
                </li>
              </ul>
            </div>

            {/*             
              <div className="details">
                <h3>Login / Signup</h3>
              </div> */}
          </div>
          {/* ......................................................................................... */}
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}

export default Navbar;
