import {
  EXTRACT_DATA_FROM_URL,
  UPDATE_ADULTS_COUNT,
  UPDATE_AGE_0TO6,
  UPDATE_AGE_7TO12,
  UPDATE_CHILDREN_COUNT,
  UPDATE_DATE_RANGE,
  UPDATE_ROOM_INFO,
} from "./actionTypes";

const initialState = {
  from_date: "",
  to_date: "",
  no_of_adults: 0,
  room_count: 0,
  no_of_children: 0,
  selected_hotel: {},
  parmas_for_api: {},
};

export const reducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case EXTRACT_DATA_FROM_URL:
      return {
        ...state,
        ...payload,
      };
    case UPDATE_DATE_RANGE:
      console.log(payload, "from update date range reducer");
      let from = payload[0];
      let to = payload[1];
      return {
        ...state,
        parmas_for_api: {
          ...state.parmas_for_api,
          from_date: from,
          to_date: to,
        },
      };
    case UPDATE_ADULTS_COUNT:
      return {
        ...state,
        parmas_for_api: {
          ...state.parmas_for_api,
          no_of_adults: payload,
        },
      };
      case UPDATE_CHILDREN_COUNT:
        return {
          ...state,
          no_of_children:payload,
          parmas_for_api: {
            ...state.parmas_for_api,
            no_of_children: payload,
          },
        }
        case UPDATE_ROOM_INFO:  
        console.log("inside room info reducer");
        return {
          ...state,
          parmas_for_api: {
            ...state.parmas_for_api,
            room_info: payload,
          },          
        }
        case UPDATE_AGE_0TO6:
          return {
            ...state,
            child_0_to_6:payload
          }
          case UPDATE_AGE_7TO12:
            return {
              ...state,
              child_7_to_12:payload
            }

    default:
      return state;
  }
};
